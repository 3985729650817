import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AuthService } from '../../services/auth-service/auth-service.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  registerForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });
  errorMessage;
  successMessage;
  showWorking = false;
  constructor(private dialogRef: MatDialogRef<SigninComponent>, private auth: AuthService) { }

  ngOnInit() {
  }

  signin() {
   this.auth.login();
   this.dialogRef.close();
  }
  tryLogin(value) {
    this.showWorking = true;
    this.auth.loginEmailPassword(value)
    .then(res => {
      this.showWorking = false;
      // console.log(res);
      this.auth.loggedInUser.next(res.user);
      this.errorMessage = '';
      this.successMessage = 'You have successfully logged in.';
      setTimeout(() => {this.dialogRef.close(); }, 2000);
    }, err => {
      this.showWorking = false;
      // console.log(err);
      this.errorMessage = err.message;
      this.successMessage = '';
    });
  }

}
