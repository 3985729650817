import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FirestoreService } from '../services/firestore.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ViewImageComponent } from '../view-image/view-image.component';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit, OnDestroy {
  watchFileSubscription: Subscription;
  newsPosts = [];
  selectedPost: any;
  viewImage: MatDialogRef<ViewImageComponent>;
  constructor(private db: FirestoreService, private view: MatDialog) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.watchFileSubscription = this.db.watchFileSubject.subscribe((data: any) => {
      this.newsPosts = data.posts;
    });
  }
  ngOnDestroy() {
    this.watchFileSubscription.unsubscribe();
  }
  select(item) {
    console.log(item);
    this.selectedPost = item;
    window.scrollTo(0, 0);
    }
    return() {
      this.selectedPost = null;
    }
    viewImg(img) {
      this.viewImage = this.view.open(ViewImageComponent, {
        data: img,
      });
    }

}
