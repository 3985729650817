import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../services/firestore.service';
import { AuthService } from '../services/auth-service/auth-service.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css']
})
export class RegisterUserComponent implements OnInit {
  newUserForm = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });

  constructor(private dialogRef: MatDialogRef<RegisterUserComponent>, private db: FirestoreService, private auth: AuthService ) { }

  tryCreate(value) {
console.log(value);
  }
  close() {
    this.dialogRef.close();
  }
  ngOnInit() {
  }

}
