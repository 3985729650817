import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { FirestoreService } from '../services/firestore.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit, OnDestroy {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 414px)'])
  .pipe(
    map(result => result.matches)
  );
  isTwo$: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 750px)'])
  .pipe(
    map(result => result.matches)
  );
  isMobile = false;
  isPortrait$: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 1040px)'])
  .pipe(
    map(result => result.matches)
  );
  isPortrait = false;
  newsLetter: FormGroup;
  watchFileSubscription: Subscription;
  adverts:any[] = [];
  advertsCntrl = [];
  two = false;
  constructor(private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private router: Router,
    private http: HttpClient,
    private db: FirestoreService) {
    this.isHandset$.subscribe(result => {
      if (result === true) {
        // console.log('matches');
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
    this.isTwo$.subscribe(result => {
      if (result === true) {
        // console.log('matches');
        this.two = true;
      } else {
        this.two = false;
      }
    });
    this.isPortrait$.subscribe(result => {
      if (result === true) {
        this.isPortrait = true;
      } else {
        this.isPortrait = false;
      }
    });
  }

  ngOnInit() {
    this.watchFileSubscription = this.db.watchFileSubject.subscribe((data: any) => {
      if (data.adverts) {
        this.adverts = data.adverts;
        console.log(this.adverts);
        this.advertsCntrl = this.adverts.filter(x=> x.status === 'Active')
      }
    });
    this.newsLetter = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
    });
    // window.scrollTo(0, 0);
    // console.log(this.isPortrait);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }
  newsLetterSubscription() {
    const uploadData = {
    name: this.newsLetter.value['name'],
    email: this.newsLetter.value['email']
    };
    this.http.post('http://www.islamiccouncilsa.co.za/subscribe_newsletter.php', uploadData)
      .subscribe((event: any) => {
        // console.log(event);
        if (event.success === true) {
        // console.log('true');
        this.newsLetter.reset();
        this.db.statusSubject.next({success: true, message: 'Newsletter request successfully submitted, thank you.'});
        }
        if (event.success === false) {
        // console.log('false');
        this.db.statusSubject.next({success: true, message: `Newsletter request unsuccessful, please
        check your internet connection and try again.`});
        }
      });
  }
  ngOnDestroy() {
    this.watchFileSubscription.unsubscribe();
  }
}
