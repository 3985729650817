// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAI4ASM_X7xdaL5SYru85gnMAHYvDK10Ok',
    authDomain: 'mjc-db.firebaseapp.com',
    databaseURL: 'https://mjc-db.firebaseio.com',
    projectId: 'mjc-db',
    storageBucket: 'mjc-db.appspot.com',
    messagingSenderId: '349477819599'

    // apiKey: 'AIzaSyDQA41qJEPmUSnxK4BWp0OMKIUG3yyVVoU',
    // authDomain: 'fds-icsa.firebaseapp.com',
    // databaseURL: 'https://fds-icsa.firebaseio.com',
    // projectId: 'fds-icsa',
    // storageBucket: 'fds-icsa.appspot.com',
    // messagingSenderId: '379866448119',
    // appId: '1:379866448119:web:9077234fa5ab2f344293f2'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
