import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger,
  style,
  animate,
  transition,
  query,
  stagger } from '@angular/animations';
  import { FirestoreService } from '../services/firestore.service';
  import { Subscription, Observable } from 'rxjs';
  import { BreakpointObserver } from '@angular/cdk/layout';
  import { map } from 'rxjs/operators';

@Component({
  selector: 'app-infinite-carousel-two',
  templateUrl: './infinite-carousel-two.component.html',
  styleUrls: ['./infinite-carousel-two.component.css'],
  animations: [
    trigger('listStagger', [
      transition('* <=> *', [
        query(
          ':enter',
          [
            style({ opacity: 0, transform: 'translateX(-15px)' }),
            stagger(
              '50ms',
              animate(
                '550ms ease-out',
                style({ opacity: 1, transform: 'translateX(0px)' })
              )
            )
          ],
          { optional: true }
        ),
        query(':leave', stagger(
          '50ms', animate('200ms', style({ opacity: 0, transform: 'translateX(15px)' }))), {
          optional: true
        })
      ])
    ])
  ]
})
export class InfiniteCarouselTwoComponent implements OnInit, OnDestroy {
  watchFileSubscription: Subscription;
  item = {
  name: 'Business name',
  imgUrl: './assets/imgs/icsathumb-300x198.png'
  };
  data = [];
  pushpop: any;
  state = 'in';
  dataLength: number;
  advertsArray: any[];
  displayContainer: any[] = [];
  counter: number;
  play = true;
  clearBeforePlay = false;
  initial = false;
  initialLoad = false;
  showloading = false;
  setFourContrl = 1;
  arrLngthCntrl = 2;
  isPortrait$: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 750px)'])
  .pipe(
    map(result => result.matches)
  );
  isMobile$: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 650px)'])
  .pipe(
    map(result => result.matches)
  );
    constructor(private db: FirestoreService, private breakpointObserver: BreakpointObserver) {
      this.isPortrait$.subscribe(result => {
        if (result === true) {
          this.setFourContrl = 1;
        } else {
          this.arrLngthCntrl = 2;
        }
      });
      // this.isMobile$.subscribe(result => {
      //   if (result === true) {
      //     this.setFourContrl = 0;
      //   } else {
      //     this.arrLngthCntrl = 1;
      //   }
      // });
    }

    ngOnInit() {
      this.watchFileSubscription = this.db.watchFileSubject.subscribe((data: any) => {
        // console.log(data);
  if (data.adverts) {
  // console.log('set');
        if (this.initialLoad = true) {
      this.showloading = true;
      setTimeout(() => {
        this.showloading = false;
        }, 1000);
        }
        clearInterval(this.pushpop);
            this.clearBeforePlay = false;
            this.displayContainer = [];
            this.data = [];
        const arr: [] = data.adverts;
        this.advertsArray = arr.filter((d: any) => d.status === 'Active');
        this.dataLength = this.advertsArray.length;
        if (this.advertsArray.length <= this.arrLngthCntrl) {
          this.play = false;
          this.setFour();
        } else {
          if (this.initial === false) {
            this.play = true;
            this.setFour();
          this.pushpop = setInterval(() => {
            // console.log('interval');
            this.setFour();
            }, 9000);
          }
        }
        this.initialLoad = true;
      }
      });
    }
    setFour() {
      if (this.clearBeforePlay === false) {
        if ( this.displayContainer.length === 0) {
          this.advertsArray.forEach(el => {
            this.displayContainer.push(el);
          });
        }
        this.dataLength = this.displayContainer.length;
        let i = 0;
        for (i = 0; i <= this.setFourContrl ; i++) {
          if (this.displayContainer.length !== 0) {
            const item = this.displayContainer.pop();
            this.data.push(item);
          }
       }
    }

  setTimeout(() => {
    if (this.play === true) {
      this.dataLength = 0;
      this.data = [];
      this.clearBeforePlay = false;
    }
  }, 8000);

    }
  onMouseLeave() {
    if (this.advertsArray.length >= 5) {
    this.play = true;
    }
  }
  onMouseEnter() {
    if (this.advertsArray.length >= 5) {
    this.clearBeforePlay = true;
    this.play = false;
    }
  }

  pushPop() {
      this.data.push(this.advertsArray[0]);
    this.dataLength = this.data.length;
    setTimeout(() => {
      this.dataLength = 0;

  this.data.pop();
    }, 8000);
  }
  openSite(url) {
    window.open(`http://${url}`, '_blank');
  }
  ngOnDestroy() {
    clearInterval(this.pushpop);
    this.watchFileSubscription.unsubscribe();
  }
  }
