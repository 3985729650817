import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
// import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { ConnectionService } from 'ng-connection-service';
import { Network } from '@ionic-native/network/ngx';
import { FirestoreService } from '../services/firestore.service';
import { MatSnackBar } from '@angular/material';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-for-upload',
  templateUrl: './for-upload.component.html',
  styleUrls: ['./for-upload.component.css']
})
export class ForUploadComponent implements OnInit, OnDestroy {
  upArr = [];
  upArrCqf = [];
  connectSubscription: any;
  disconnectSubscription: any;
  connectionStatus: boolean;
  showProgress = false;
  changeRef: ChangeDetectorRef;
  constructor(
    // private nativeStorage: NativeStorage,
    private storage: Storage,
    private connectionService: ConnectionService,
    private network: Network,
    private db: FirestoreService,
    changeDetector: ChangeDetectorRef,
    private snackBar: MatSnackBar) {
    this.connectionService.monitor().subscribe(isConnected => {
      if (isConnected) {
        this.connectionStatus = true;
        // console.log(this.connectionStatus);
        changeDetector.detectChanges();
      } else {
        this.connectionStatus = false;
        // console.log(this.connectionStatus);
        changeDetector.detectChanges();
      }
    });
  }

  ngOnInit() {
    this.connectionStatus = window.navigator.onLine;
    document.addEventListener('deviceready', () => {
      this.mobileNetwork();
      this.getFromStorage();
    });
  }
  getFromStorage() {
    this.storage.get('uploads')
      .then(
        data => {
          if (data) {
            this.upArr = JSON.parse(data.toUpload);
          }
        }
      ).catch(err => console.error(err));
    this.storage.get('cqf')
      .then(
        data => {
          if (data) {
            this.upArrCqf = JSON.parse(data.toUpload);
            console.log(this.upArrCqf + 'cqf');
          }
        }
      ).catch(err => console.error(err));
  }
  upload() {
    this.showProgress = true;
    const snackBarRef1 = this.snackBar.open('Uploading documents, please wait', 'Dissmiss');
    snackBarRef1.onAction().subscribe(() => {
      snackBarRef1.dismiss();
    });
    if (this.upArr.length >= 1) {
      this.uploadIf().then(() => {
        if (this.upArrCqf.length >= 1) {
          this.uploadCqf().then(() => {
                // toast
          });
        }
      }).catch(error => {
        console.log(error);

        // toast here
        const snackBarRef = this.snackBar.open(`${error}`, 'Dissmiss');
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      });
    } else {
      this.uploadCqf().then(() => {
                // toast

      }).catch(error => {
        console.log(error);

        const snackBarRef = this.snackBar.open(`${error}`, 'Dissmiss');
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      });
    }


  }
 async uploadIf() {
   await this.upArr.forEach((elem, index) => {
      this.db.submitFormIf(elem);
      this.db.statusSubjectUploads.subscribe(data => {
        if (data.done === true) {
          setTimeout(() => {
            this.db.deleteTaskUploads(elem.taskData);
            this.upArr.splice(index, 1);
            this.db.pushUploadsCount(this.upArr.length);
            if (this.upArr.length === 0 && this.upArrCqf.length === 0) {
              const snackBarRef = this.snackBar.open('Uploads complete', 'Dissmiss');
              snackBarRef.onAction().subscribe(() => {
                snackBarRef.dismiss();
              });
            }
          }, 1000);
        } else {
          console.log('error uploading documents');
          // toast
        }
      });
      });
  }
 async uploadCqf() {
await this.upArrCqf.forEach((elem, index) => {
this.db.submitClientQueryCqf(elem);
this.db.statusSubjectUploads.subscribe(data => {
  if (data.done === true) {
    setTimeout(() => {
      this.upArrCqf.splice(index, 1);
      this.db.pushUploadsCountCqf(this.upArrCqf.length);
      if (this.upArr.length === 0 && this.upArrCqf.length === 0) {
        const snackBarRef = this.snackBar.open('Uploads complete', 'Dissmiss');
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }
    }, 1000);
  } else {
    console.log('error uploading documents');
    this.showProgress = false;
  }
});
});

  }
  mobileNetwork() {
    this.disconnectSubscription = this.network.onDisconnect().subscribe(() => {
      this.connectionStatus = true;
      console.log(this.connectionStatus);
    });
    this.connectSubscription = this.network.onConnect().subscribe(() => {
      this.connectionStatus = false;
      console.log(this.connectionStatus);
      // We just got a connection but we need to wait briefly
      // before we determine the connection type. Might need to wait.
      // prior to doing any api requests as well.
      // setTimeout(() => {
      //   if (this.network.type === 'wifi') {
      //     console.log('we got a wifi connection, woohoo!');
      //     console.log(this.network.type);
      //     console.log(this.network);
      //   }
      // }, 3000);
    });
  }
  ngOnDestroy() {

  }

}
