import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { FirestoreService } from '../services/firestore.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog} from '@angular/material';
import { DatePipe } from '@angular/common';
import { UploaderComponent } from '../uploader/uploader.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { finalize, tap, map } from 'rxjs/operators';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { ViewDocDetailsComponent } from '../view-doc-details/view-doc-details.component';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.css']
})
export class NewRequestComponent implements OnInit, OnDestroy {
@ViewChild('inputFile_1') myInputVariable1: ElementRef;
@ViewChild('inputFile_2') myInputVariable2: ElementRef;
@ViewChild('inputFile_3') myInputVariable3: ElementRef;
infoRef: MatDialogRef<ViewDocDetailsComponent>;
  // Main task
task: AngularFireUploadTask;

// Progress monitoring
percentage: Observable<number>;

snapshot: Observable<any>;

// Download URL
downloadURL: Observable<string>;
selectedFile: Array<any> = [];
// file: any;
fileArr: any[];
taskSubscription: Subscription;
arrLengthCntrl: any;
uploadCount: number;
addCertificateForm: FormGroup;
  date: any;
  data: {requestDate: any, advert: any, time: any, uploadedDocuments: any[], status: any, owner: string};
  time: any;
  // documentUrl: any;
  // documentName: any;
  uploadsComplete = false;
  uploadedDocuments: Array<any> = [];
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 414px)'])
  .pipe(
    map(result => result.matches)
  );
isMobile = false;
isPortrait$: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 985px)'])
  .pipe(
    map(result => result.matches)
  );
  isPortrait = false;
enableFirstStep = false;
response: Subscription;
@ViewChild('upload') uploader: UploaderComponent;
submitMessage = `Please note that the completed application form is compulsory.
Please keep your hard copy safe as it will be required by the inspector conducting the preliminary inspection.`;
@ViewChild('changebtn') private changebtn: ElementRef;
@ViewChild('stepper') private stepper: MatStepper;
submitLoader = false;
doc1: any;
doc2: any;
doc3: any;
doc4: any;
uploadArr: any[];
docDlArr: any[];
disableSubmit = false;
step1 = false;
step2 = false;
step3 = false;
advertiseOptIn = false;
uploadSize = 0;
uploadSizeMb = 0;
  constructor(
              private db: FirestoreService, public datepipe: DatePipe, private dialog: MatDialog,
              private breakpointObserver: BreakpointObserver, private storage: AngularFireStorage,
  private dbf: AngularFirestore) {
                this.isHandset$.subscribe(result => {
                  if (result === true) {
                    // console.log('matches');
                    this.isMobile = true;
                  } else {
                    this.isMobile = false;
                  }
                });
                this.isPortrait$.subscribe(result => {
                  if (result === true) {
                    this.isPortrait = true;
                  } else {
                    this.isPortrait = false;
                  }
                });
               }
ngOnInit() {
  this.enableFirstStep = true;
this.addCertificateForm = new FormGroup({
  name: new FormControl('', Validators.required),
  contactPerson: new FormControl('', Validators.required),
  address: new FormControl('', Validators.required),
  note: new FormControl(''),
  tel: new FormControl('' , Validators.compose([Validators.required, Validators.minLength(9)])),
  email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
});
this.response = this.db.statusSubject.subscribe(data => {
  const res: any = data;
  if (res.success === true) {
    this.submitLoader = false;
    this.submitMessage = 'Thank you for your submission.';
    setTimeout(() => {
      this.stepper.reset();
      this.close();
    }, 4000);
  }
  if (res.success === false) {
    this.submitLoader = false;
    this.submitMessage = 'There was an error submitting your requeset, please check your internet connection or try again later.';
    setTimeout(() => {
      this.stepper.reset();
      this.close();
    }, 4000);
  }
});
const clearFocus = setInterval(() => {
  this.changebtn['_elementRef'].nativeElement.classList.remove('cdk-program-focused');
  }, 50);
  setTimeout(() => {clearInterval(clearFocus); }, 5000);
}

  tryAdd() {
    this.date = Date.now();
    const latest_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    const latest_time = this.datepipe.transform(this.date, 'HH:mm');
    this.data = this.addCertificateForm.value;
    this.data.requestDate = latest_date;
    this.data.time = latest_time;
    this.data.status = 'Send to food tech',
    this.data.owner = 'Reception',
    this.data.uploadedDocuments = this.docDlArr;
    if (this.advertiseOptIn === true) {
      this.data.advert = true;
    }
    // console.log(this.data);
    this.db.sendNewReq(this.data);
    }


    close() {
      this.submitMessage = `Please note that the completed application form is compulsory.
      Please keep your hard copy safe as it will be required by the inspector conducting the preliminary inspection.`;
      this.doc1 = null;
        this.doc2 = null;
        this.doc3 = null;
        this.doc4 = null;
        this.docDlArr = [];
        this.uploadArr = [];
        this.addCertificateForm.reset();
      // this.dialogRef.close();
      // grecaptcha.reset();
      this.myInputVariable1.nativeElement.value = '';
      if (this.myInputVariable2) {
        this.myInputVariable2.nativeElement.value = '';
      }
      if (this.myInputVariable3) {
        this.myInputVariable3.nativeElement.value = '';
      }
    }
    onUploadComplete(data) {
    const documentUrl = data.documentUrl;
    const documentName = data.file;
    this.uploadedDocuments.push({documentUrl: documentUrl, documentName: documentName});
    // console.log(this.uploadedDocuments);
    if (this.uploadedDocuments.length === data.arrLength) {
      this.uploadsComplete = true;
      this.uploader.hideProgress = true;
    }
  }

  resetUpload() {
this.uploader.reset();
  }
//   resolved(captchaResponse: string) {
//     console.log(`Resolved captcha with response ${captchaResponse}:`);
// }
  resolved(event) {
    // console.log(event);
    if (event) {
    this.enableFirstStep = true;
    }
}
ngOnDestroy () {
this.response.unsubscribe();
}
document1(event: FileList) {
this.doc1 = event.item(0);
// console.log(this.doc1);
}
document2(event: FileList) {
this.doc2 = event.item(0);
// console.log(this.doc1);
}
document3(event: FileList) {
this.doc3 = event.item(0);
// console.log(this.doc1);
}
document4(event: FileList) {
this.doc4 = event.item(0);
// console.log(this.doc1);
}
checkNextDissable() {
  if (this.doc1 && this.uploadSizeMb <= 20) {
return false;
  } else {
    return true;
  }
}
constructUploadArr() {
this.docDlArr = [];
this.uploadArr = [];
if (this.doc1) {
  this.uploadArr.push(this.doc1);
}
if (this.doc2) {
  this.uploadArr.push(this.doc2);
}
if (this.doc3) {
  this.uploadArr.push(this.doc3);
}
// console.log(this.uploadArr);
// this.startUpload(this.uploadArr);
this.uploadArr.forEach(el => {
this.uploadSize = this.uploadSize + el.size;
});
this.uploadSizeMb = this.uploadSize / (1024 * 1024);
// console.log(this.uploadSizeMb);
if (this.uploadSizeMb > 20) {
  this.submitMessage = 'There is a file upload limit of 20mb, please reduce your file size.';

} else {
  this.submitMessage = `Please note that the completed application form is compulsory.
  Please keep your hard copy safe as it will be required by the inspector conducting the preliminary inspection.`;

}
}
startUpload() {
  if (this.db.connectionStatus) {
  this.submitMessage = 'Uploading documents... please wait.';
  this.submitLoader = true;
  const y = this.uploadArr;
  this.uploadCount++;
  const d: any[] = y;
  // this.fileArr = event.target.files
  // let reservationArr : Array<object> = [];
  let i;
  i = 0;
  for (i = 0; i < d.length; i++) {
  // console.log(event.item(i));


  // The File object
  const uploadArrLength = d.length;
  this.arrLengthCntrl = uploadArrLength;
  const file = d[i];
  this.selectedFile.push(d[i].name);
  // Client-side validation example
  // if (file.type.split('/')[0] !== '.pdf') {
  //   console.error('unsupported file type :( ');
  //   return;
  // }
  // console.log(file.type);

  // The storage path
  const path = `certReq/${new Date().getTime()}_${file.name}`;

  // Totally optional metadata
  const customMetadata = { size: `${file.size}` };

  // The main task
  this.task = this.storage.upload(path, file, { customMetadata });

  // Progress monitoring
  this.percentage = this.task.percentageChanges();
  this.snapshot   = this.task.snapshotChanges().pipe(
    tap(snap => {
      if (snap.bytesTransferred === snap.totalBytes) {
        // Update firestore on completion
        // this.dbf.collection('photos').add( { path, size: snap.totalBytes });
        // this.hideProgress = true;
      }
    })
  );
  const fileRef = this.storage.ref(path);
  // The file's download URL
//  this.downloadURL = this.task.downloadURL();
this.taskSubscription = this.task.snapshotChanges().pipe(
  finalize(() => {
    this.downloadURL = fileRef.getDownloadURL();
    this.downloadURL.subscribe(data => {
      const x = {documentUrl: data, file: file.name, arrLength: uploadArrLength};
      // this.eventUploadUrl.emit(x);
      this.docDlArr.push(x);
      if (this.docDlArr.length === this.uploadArr.length) {
this.tryAdd();
      }
      // console.log(data);
    }, err => {
      this.submitMessage = 'Your submission could not be completed at this time, please check your internet connection.';
      this.submitLoader = false;
      // console.error('Oops:', err.message);
    });
  } )
)
.subscribe();
}
} else {
  this.db.statusSubject.next({success: false, message: `Your submission could not be completed at this time, please check your internet
  connection.`});

}
}
download() {
  window.open('./assets/data/ICSA_APPLICATION_FORM.2018.pdf', '_parent');
}
view(data) {
  this.infoRef = this.dialog.open(ViewDocDetailsComponent, {data: data});
}
adverts(data) {
if (data.checked === true) {
this.advertiseOptIn = true;
} else {
  this.advertiseOptIn = false;
}
}
}
