import { Component, OnInit } from '@angular/core';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-csv-uploader',
  templateUrl: './csv-uploader.component.html',
  styleUrls: ['./csv-uploader.component.css']
})
export class CsvUploaderComponent implements OnInit {

  constructor(private papa: Papa) { }
  onFilesAdded(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log(file);
      this.papa.parse(file, {
        complete: (result) => {
            console.log('Parsed: ', result);
        }
    });
    }
  }
  ngOnInit() {
  }

}
