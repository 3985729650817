import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger,
  style,
  animate,
  transition,
  query,
  stagger } from '@angular/animations';
  import { FirestoreService } from '../services/firestore.service';
  import { Subscription } from 'rxjs';
@Component({
  selector: 'app-infinite-carousel',
  templateUrl: './infinite-carousel.component.html',
  styleUrls: ['./infinite-carousel.component.css'],
  animations: [
    trigger('listStagger', [
      transition('* <=> *', [
        query(
          ':enter',
          [
            style({ opacity: 0, transform: 'translateX(-15px)' }),
            stagger(
              '50ms',
              animate(
                '550ms ease-out',
                style({ opacity: 1, transform: 'translateX(0px)' })
              )
            )
          ],
          { optional: true }
        ),
        query(':leave', stagger(
          '50ms', animate('200ms', style({ opacity: 0, transform: 'translateX(15px)' }))), {
          optional: true
        })
      ])
    ])
  ]
  // animations: [
  //   trigger('flyInOut', [
  //     state('in', style({opacity: 1})),
  //     transition('void => *', [
  //       style({opacity: 0}),
  //       animate(400)
  //     ]),
  //     transition('* => void', [
  //       animate(400, style({opacity: 0}))
  //     ])
  //   ])
  // ]
})
export class InfiniteCarouselComponent implements OnInit, OnDestroy {
watchFileSubscription: Subscription;
item = {
name: 'Business name',
imgUrl: './assets/imgs/icsathumb-300x198.png'
};
data = [];
pushpop: any;
state = 'in';
dataLength: number;
advertsArray: any[];
displayContainer: any[] = [];
counter: number;
play = true;
clearBeforePlay = false;
initial = false;
initialLoad = false;
showloading = false;
  constructor(private db: FirestoreService) { }

  ngOnInit() {
    this.watchFileSubscription = this.db.watchFileSubject.subscribe((data: any) => {
      // console.log(data);
if (data.adverts) {
// console.log('set');
      if (this.initialLoad = true) {
    this.showloading = true;
    setTimeout(() => {
      this.showloading = false;
      }, 1000);
      }
      clearInterval(this.pushpop);
          this.clearBeforePlay = false;
          this.displayContainer = [];
          this.data = [];
      const arr: [] = data.adverts;
      this.advertsArray = arr.filter((d: any) => d.status === 'Active');
      this.dataLength = this.advertsArray.length;
      if (this.advertsArray.length <= 4) {
        // clearInterval(this.pushpop);
        // this.clearBeforePlay = false;
        // this.displayContainer = [];
        // this.data = [];
        this.play = false;
        this.setFour();
        // console.log('less than 4');
      } else {
        if (this.initial === false) {
          // clearInterval(this.pushpop);
          // this.clearBeforePlay = false;
          // this.displayContainer = [];
          // this.data = [];
          this.play = true;
          // this.initial = true;
          this.setFour();
        this.pushpop = setInterval(() => {
          console.log('interval');
          this.setFour();
          }, 9000);
        }
        // console.log(this.advertsArray);
        // this.initial = true;
      }
      // clearInterval(this.pushpop);
      // this.pushPop();
      this.initialLoad = true;
    }
    });
    // this.pushPop();
    // this.pushpop = setInterval(() => {
    //   this.pushPop();
    //   }, 9000);
      // setTimeout(() => {clearInterval(pushpop); }, 5000);
  }
  setFour() {
    if (this.clearBeforePlay === false) {
      if ( this.displayContainer.length === 0) {
        this.advertsArray.forEach(el => {
          this.displayContainer.push(el);
        });
      }
      this.dataLength = this.displayContainer.length;
      let i = 0;
      for (i = 0; i <= 3 ; i++) {
        if (this.displayContainer.length !== 0) {
          const item = this.displayContainer.pop();
          this.data.push(item);
        }
     }
  }

setTimeout(() => {
  if (this.play === true) {
    this.dataLength = 0;
    // this.data.forEach(element => {
    //   this.data.pop();
    // });
    this.data = [];
    this.clearBeforePlay = false;
  }
}, 8000);

  }
onMouseLeave() {
  if (this.advertsArray.length >= 5) {
  // console.log('leave');
  this.play = true;
  }
}
onMouseEnter() {
  if (this.advertsArray.length >= 5) {
  // console.log('enter');
  this.clearBeforePlay = true;
  this.play = false;
  }
}

pushPop() {
  // if (this.advertsArray) {
    this.data.push(this.advertsArray[0]);
  // }

  // this.data.push(this.item);
  // this.data.push(this.item);
  // this.data.push(this.item);
  this.dataLength = this.data.length;
  setTimeout(() => {
    this.dataLength = 0;
// this.data.pop();
// this.data.pop();
// this.data.pop();
this.data.pop();
  }, 8000);
}
openSite(url) {
  window.open(`http://${url}`, '_blank');
}
ngOnDestroy() {
  clearInterval(this.pushpop);
  this.watchFileSubscription.unsubscribe();
}
}
