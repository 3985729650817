import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Router } from '@angular/router';
import { FirestoreService } from '../firestore.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private user: firebase.User;
  public loggedInUser = new BehaviorSubject({});
  public role: any;
  authSub: Subscription;
  userRoleSub: Subscription;
  constructor(public afAuth: AngularFireAuth, private router: Router, private db: FirestoreService) {
  }
  login() {
    this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider()).then(credentials => {
      // console.log(credentials);
      this.initUser();

    });
  }
  initUser() {
    this.authSub = this.afAuth.authState.subscribe(user => {
      this.user = user;
      this.loggedInUser.next(user);
      // console.log(this.user);
      if (user) {
        this.userRoles(this.user.email);
      }
    });
  }
  logout() {
    // console.log(this.role);
    if (this.role === 'sheikh') {
      this.db.querySub.unsubscribe();
      this.db.processedQuerySub.unsubscribe();
    }
    if (this.role === 'inspector') {
      this.db.halalSub.unsubscribe();
      this.db.taskSub.unsubscribe();
      this.db.hrSub.unsubscribe();
      this.db.reqSub.unsubscribe();
    }
    if (this.role === 'head inspector') {
      this.db.halalSub.unsubscribe();
      this.db.taskSub.unsubscribe();
      this.db.hrSub.unsubscribe();
      this.db.reqSub.unsubscribe();
    }
    if (this.role === 'admin') {
      this.db.certSub.unsubscribe();
      this.db.halalSub.unsubscribe();
      this.db.inspSub.unsubscribe();
      this.db.miscSub.unsubscribe();
      this.db.roleSub.unsubscribe();
      this.db.taskSub.unsubscribe();
      this.db.hrSub.unsubscribe();
      this.db.reqSub.unsubscribe();
    }

    this.afAuth.auth.signOut();
    this.router.navigate(['Home']);
    // console.log('logout');
    this.db.userRoleDataSubject.next({ role: null });
    this.authSub.unsubscribe();
    this.userRoleSub.unsubscribe();
  }

  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.auth.createUserWithEmailAndPassword(value.email, value.password)
        .then(res => {
          resolve(res);
        }, err => reject(err));
    });
  }
  loginEmailPassword(value) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(value.email, value.password)
        .then(res => {
          this.userRoles(res.user.email);
          resolve(res);
        }, err => reject(err));
    });
  }
  userRoles(email) {
    this.db.userRole(email);
    this.userRoleSub = this.db.userRoleDataSubject.pipe(tap((role: any) => {
      this.role = role.role;
      if (role.role === 'sheikh') {
        this.db.getClientQueryData();
        this.db.getProcessedClientQueryData();
        this.router.navigate(['Client-query-signage']);
      }
      if (role.role === 'inspector') {
        this.db.getCertificateData();
        this.db.getRequests();
        this.db.getHrData();
        this.db.getTasks();
        this.db.getHalalData();
        this.router.navigate(['Site-inspections']);
      } if (role.role === 'head inspector') {
        this.db.getCertificateData();
        this.db.getRequests();
        this.db.getHrData();
        this.db.getTasks();
        this.db.getHalalData();
        this.router.navigate(['Site-inspections']);
      } if (role.role === 'admin') {
        this.db.getCertificateData();
        this.db.getRequests();
        this.db.getHrData();
        this.db.getTasks();
        this.db.getHalalData();
        this.db.getMisconductData();
        this.db.getInspSubmitData();
        this.router.navigate(['Dashboard']);
      } if (role.role === '') {
        this.router.navigate(['Home']);
      }
    })).subscribe((role: any) => {
      // console.log(role);
    });
  }

  getUserData() {
    return this.afAuth.user;
  }
  getUser() {
    return this.user;
  }
}
