import { Component, ChangeDetectorRef, OnDestroy, ViewChild, OnInit, NgZone, ElementRef, AfterViewInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { AuthService } from './services/auth-service/auth-service.service';
import { MatDialog, MatDialogRef, MatMenuTrigger } from '@angular/material';
import { AdvertApplicationComponent } from './advert-application/advert-application.component';
import { FirestoreService } from './services/firestore.service';
import { Platform } from '@angular/cdk/platform';
import { MatSnackBar } from '@angular/material';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
declare var device;
declare var sqlitePlugin;
declare var file;
import { ConnectionService } from 'ng-connection-service';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FocusMonitor } from '@angular/cdk/a11y';
import { trigger,
  style,
  animate,
  transition,
  state} from '@angular/animations';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('EnterLeave', [
      state('flyIn', style({ transform: 'translateY(0%)' })),
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('0.1s linear')
      ]),
      transition(':leave', [
        animate('0.3s ease-out', style({ transform: 'translateY(-100%)' }))
      ])
    ]),
    trigger('EnterLeave_', [
      state('collapsed', style({
        transform: 'rotate(0deg)'
      }), {params: {min_height: '3em'}}),
      state('expanded', style({
        transform: 'rotate(90deg)'
      })),
      transition('collapsed <=> expanded', animate('0.3s ease'))
    ])
  ]
})
export class AppComponent implements OnDestroy, OnInit, AfterViewInit {
  title: any;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  // @ViewChild(MainNavComponent)
  // private navComponent: MainNavComponent;
  user$: any;
  currentUser: any;
  dialogRef: MatDialogRef<AdvertApplicationComponent>;
  localData: any;
  statusEvent: any;
  isTablet$: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 768px)'])
    .pipe(
      map(result => result.matches)
    );
  isTablet: any;
  sqliteDb: any;
  status: any = 'ONLINE';
  isConnected: any = true;
  wifiStatus: any;
  @ViewChild('changebtn') private changebtn: ElementRef;
  @ViewChild('aboutMenuRef') private aboutMenuRef: ElementRef;
  @ViewChild('aboutMenuRef1') private aboutMenuRef1: ElementRef;
  @ViewChild('changebtn_about') private changebtn_about: ElementRef;
  @ViewChild('menuTrigger') private menuTrigger: MatMenuTrigger;
  showMenu = false;
  menuState = 'collapsed';
  aboutState = 'collapsed';
  aboutCntrl = false;
  constructor(changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private authService: AuthService,
    private dialog: MatDialog,
    private db: FirestoreService, private _platform: Platform, public snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver,
    private router: Router, private connectionService: ConnectionService,
    private statusBar: StatusBar, private zone: NgZone, private _focusMonitor: FocusMonitor) {
    this.mobileQuery = media.matchMedia('(max-width: 850px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.isTablet$.subscribe(result => {
      if (result === true) {
        this.isTablet = true;
      } else {
        this.isTablet = false;
      }
    });
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.db.connectionStatus = true;
        this.status = 'Cloud link established.';
        this.wifiStatus = true;
        // console.log(this.status);
        changeDetectorRef.detectChanges();
      } else {
        this.db.connectionStatus = false;
        this.status = 'No internet connection.';
        this.wifiStatus = false;
        // console.log(this.status);
        changeDetectorRef.detectChanges();
      }
    });
  }
  ngAfterViewInit() {
}
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.db.watchFileSub.unsubscribe();
    // this.authService.logout();
  }
  test() {
    if (this.showMenu) {
      this.showMenu = false;
      this.menuState = 'collapsed';
    } else {
      this.showMenu = true;
      this.menuState = 'expanded';
    }
  }
  about() {
    if (this.aboutState === 'expanded') {
      this.aboutState = 'collapsed';
    } else {
      this.aboutState = 'expanded';
    }
  }
  focused() {
    // console.log(this.aboutMenuRef);
  }
  openMenu() {
    this.menuTrigger.openMenu();
  }
  clearFocus() {
    this.changebtn_about['_elementRef'].nativeElement.classList.remove('cdk-program-focused');
  }
  ngOnInit() {
    window.scrollTo(0, 0);
    this.title = 'app';
  // this.isConnected = true;
    this.wifiStatus = window.navigator.onLine;
    this.db.startListeningWatchFile();
    if (this.status === 'ONLINE') {
      this.db.connectionStatus = true;
    } else {
    this.db.connectionStatus = false;
    }
    // this.user$ = this.authService.getUserData();
    // this.currentUser = this.authService.getUser();
    // this.db.getCertificateData();
    // this.db.getRequests();
    // this.db.getHrData();
    // this.db.getTasks();
    // this.db.getHalalData();
    this.db.statusSubject.subscribe(status => {
      this.statusEvent = status;
      if (this.statusEvent.success === true) {
        this.zone.run(() => {
        const snackBarRef = this.snackBar.open(`${this.statusEvent.message}`, 'Dissmiss', { duration: 8000, });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      });
      } if (this.statusEvent.success === false) {
        this.zone.run(() => {
        const snackBarRef = this.snackBar.open(`${this.statusEvent.message}`, 'Dissmiss');
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      });
      }
    });
    document.addEventListener('deviceready', () => {
      // console.log('deviceready');
      this.statusBar.styleBlackTranslucent();
    });
  }

  applyForAdvertising() {
    this.dialogRef = this.dialog.open(AdvertApplicationComponent);
    // this.dialogRef.afterClosed().subscribe(() => {
    //     this.changebtn['_elementRef'].nativeElement.classList.remove('cdk-program-focused');
    // });
  }
  logout() {
    this.authService.logout();
  }
aboutSet() {
  this.aboutCntrl = true;
}
aboutUnset() {
  this.aboutCntrl = false;
}
}

