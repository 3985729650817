import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-view-doc-details',
  templateUrl: './view-doc-details.component.html',
  styleUrls: ['./view-doc-details.component.css']
})
export class ViewDocDetailsComponent implements OnInit {
message: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ViewDocDetailsComponent>) { }

  ngOnInit() {
    // console.log(this.data);
    switch (this.data) {
case 'application form' : this.message = `Please complete and scan all of the documents to a single pdf document.`;
  break;
case 'muslim staff' : this.message = `Please compile a single pdf with your supporting documents.`;
    break;
case 'advert' : this.message = `The image will be cropped to 600*400 pixels to display on the site,
please take this into consideration when selecting an image for advertising`;
    break;
    }
  }
  close() {
    this.dialogRef.close();
  }

}
