import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { AgmCoreModule } from '@agm/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatTabsModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatCardModule,
  MatMenuModule,
  MatDialogModule,
  MatExpansionModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatOptionModule,
  MatSelectModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatStepperModule,
  MatProgressBarModule,
  MatRadioModule,
  MatDatepickerModule,
  MAT_DATE_FORMATS,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
  MatTreeModule,
  MatProgressSpinnerModule,
  MatCheckboxModule,
} from '@angular/material';
import {MatListModule} from '@angular/material/list';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SignupComponent } from './auth/signup/signup.component';
import { SigninComponent } from './auth/signin/signin.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { VarifyDelComponent } from './varify-del/varify-del.component';
import { DatePipe } from '@angular/common';
import { UploaderComponent } from './uploader/uploader.component';
import { DropZoneDirective } from './directives/drop-zone.directive';
import { RecaptchaModule } from 'ng-recaptcha';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RegisterUserComponent } from './register-user/register-user.component';
import { Network } from '@ionic-native/network/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { File } from '@ionic-native/File/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { CsvUploaderComponent } from './csv-uploader/csv-uploader.component';
import { PapaParseModule } from 'ngx-papaparse';
import { NewRequestComponent } from './new-request/new-request.component';
import { ViewDocDetailsComponent } from './view-doc-details/view-doc-details.component';
import { InfiniteCarouselComponent } from './infinite-carousel/infinite-carousel.component';
import { InfiniteCarouselTabletComponent } from './infinite-carousel-tablet/infinite-carousel-tablet.component';
import { InfiniteCarouselMobileComponent } from './infinite-carousel-mobile/infinite-carousel-mobile.component';
import { InfiniteCarouselTwoComponent } from './infinite-carousel-two/infinite-carousel-two.component';
import { AdvertApplicationComponent } from './advert-application/advert-application.component';
import { ViewImageComponent } from './view-image/view-image.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY-MM-DD',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'YYYY-MM-DD',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    SignupComponent,
    SigninComponent,
    VarifyDelComponent,
    UploaderComponent,
    DropZoneDirective,
    RegisterUserComponent,
    CsvUploaderComponent,
    NewRequestComponent,
    ViewDocDetailsComponent,
    InfiniteCarouselComponent,
    InfiniteCarouselTabletComponent,
    InfiniteCarouselMobileComponent,
    InfiniteCarouselTwoComponent,
    AdvertApplicationComponent,
    ViewImageComponent,
  ],
  imports: [
    PapaParseModule,
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule.enablePersistence(),
    AngularFireStorageModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDjYd793sjZTDAWSrTGz059VuITA5VJEck'
      // apiKey: 'AIzaSyAI4ASM_X7xdaL5SYru85gnMAHYvDK10Ok'
    }),
    RecaptchaModule.forRoot(),
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCheckboxModule,
    MatCardModule,
    MatMenuModule,
    MatDialogModule,
    MatExpansionModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    HttpClientModule,
    MatTableModule,
    MatTabsModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    MatOptionModule,
    MatBadgeModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
    MatStepperModule,
    MatProgressBarModule,
    MatRadioModule,
    PdfViewerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTreeModule,
    IonicStorageModule.forRoot()
  ],
  entryComponents: [SigninComponent,
    ViewDocDetailsComponent,
    VarifyDelComponent,
    RegisterUserComponent,
    AdvertApplicationComponent,
    ViewImageComponent
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy },
    DatePipe,
    // NativeStorage,
    Network,
    StatusBar,
    File,
    Geolocation,
    Camera,
    WebView,
    AndroidPermissions,
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

