import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject, Subject, Subscription, Subscribable } from 'rxjs';
import { map} from 'rxjs/operators';
import * as firebase from 'firebase/app';
export interface Certificate { name: string; address: string; status: string; id: string; }

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
certificateData$: Observable<any>;
public certDataSubject = new BehaviorSubject([]);
certSub: Subscription;

inspSubmitData$: Observable<any>;
public inspSubmitDataSubject = new BehaviorSubject([]);
inspSub: Subscription;

clientQueryData$: Observable<any>;
public clientQueryDataSubject = new BehaviorSubject([]);
querySub: Subscription;

processedClientQueryData$: Observable<any>;
public processedClientQueryDataSubject = new BehaviorSubject([]);
processedQuerySub: Subscription;

misconductData$: Observable<any>;
public misconductDataSubject = new BehaviorSubject([]);
miscSub: Subscription;

requestsData$: Observable<any>;
public requestsDataSubject = new BehaviorSubject([]);
reqSub: Subscription;

taskData$: Observable<any>;
public taskDataSubject = new BehaviorSubject([]);
taskSub: Subscription;

hrData$: Observable<any>;
public hrDataSubject = new BehaviorSubject([]);
hrSub: Subscription;

halalData$: Observable<any>;
public halalDataSubject = new BehaviorSubject([]);
halalSub: Subscription;

public statusEventSubject = new BehaviorSubject({success: null, message: ''});
public statusSubject: any = new Subject;
public statusSubjectUploads: any = new Subject;

public activeDropdownSubject = new BehaviorSubject({init: 'init value', isInit: 'Initializing'});

userRole$: Observable<any>;
public userRoleDataSubject = new BehaviorSubject({role: ''});
roleSub: Subscription;

public expandState = new BehaviorSubject({});

public uploadsCountSubject = new BehaviorSubject(0);
public uploadsCountSubjectCqf = new BehaviorSubject(0);

watchFile$: Observable<any>;
// public watchFileSubject: any = new Subject;
public watchFileSubject = new BehaviorSubject([]);
watchFileSub: Subscription;
public connectionStatus = false;

staffOrderData$: Observable<any>;
public staffOrderDataSubject = new BehaviorSubject([]);
staffOrderSub: Subscription;
constructor(private db: AngularFirestore) { }

get timestamp() {
  return firebase.firestore.FieldValue.serverTimestamp();
}

pushUploadsCount(count) {
this.uploadsCountSubject.next(count);
}
pushUploadsCountCqf(count) {
this.uploadsCountSubjectCqf.next(count);
}
// Processed Client Query methods
getProcessedClientQueryData() {
  this.processedClientQueryData$ = this.db.collection('processedClientQueries').snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as Certificate;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
 this.processedQuerySub = this.processedClientQueryData$.subscribe(data => {
    this.processedClientQueryDataSubject.next(data);
    // console.log(data);
  });
  // console.log('getData');
}
submitProcessedClientQuery(item) {
  const timestamp = this.timestamp;
  this.db.collection('processedClientQueries').add({
    ...item,
    updatedAt: timestamp,
    createdAt: timestamp,
  }).then((docRef) => {
  //  console.log('Document written with ID: ', docRef.id);
   this.statusSubject.next({done: true, success: true, message: 'Client query successfully processed!'});
 })
 .catch((error) => {
   console.error('Error adding document: ', error);
   this.statusSubject.next({success: false, message: `Error processing client query,
   please check internet connection or log a support request.`});
 });
 }
 deleteProcessedClientQuery(item) {
   this.db.collection('processedClientQueries').doc(item.id).delete().then( () => {
     this.statusSubject.next({success: true, message: 'Client query data successfully deleted!'});
    //  console.log('Document successfully deleted');
 }).catch((error) => {
     console.error('Error removing document: ', error);
     this.statusSubject.next({success: false, message: `Error deleting inspection data,
     please check internet connection or log a support request.`});
 });
 }
 updateProcessedClientQuery(item, id) {
   const timestamp = this.timestamp;
  //  console.log(item);
   this.db.collection('processedClientQueries').doc(id).update({
    ...item,
    updatedAt: timestamp,
  }).then( () => {
    //  console.log('Document successfully updated');
     this.statusSubject.next({success: true, message: 'form successfully updated!'});
 }).catch((error) => {
     this.statusSubject.next({success: false, message: `Error updating form,
     please check internet connection or log a support request.`});
     console.error(error);
 });
 }


// Client Query methods
getClientQueryData() {
  this.clientQueryData$ = this.db.collection('clientQuery').snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as Certificate;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
 this.querySub = this.clientQueryData$.subscribe(data => {
    this.clientQueryDataSubject.next(data);
    // console.log(data);
  });
  // console.log('getData');
}
submitClientQuery(item) {
  const timestamp = this.timestamp;
  this.db.collection('clientQuery').add({
    ...item,
    updatedAt: timestamp,
    createdAt: timestamp,
  }).then((docRef) => {
  //  console.log('Document written with ID: ', docRef.id);
   this.statusSubject.next({done: true, success: true, message: 'Client query successfully sent to the database!'});
 })
 .catch((error) => {
  //  console.error('Error adding document: ', error);
   this.statusSubject.next({success: false, message: `Error adding client query,
   please check internet connection or log a support request.`});
 });
 }
submitClientQueryCqf(item) {
  const timestamp = this.timestamp;
  this.db.collection('clientQuery').add({
    ...item,
    updatedAt: timestamp,
    createdAt: timestamp,
  }).then((docRef) => {
  //  console.log('Document written with ID: ', docRef.id);
   this.statusSubjectUploads.next({done: true, success: true, message: 'Client query successfully sent to the database!'});
 })
 .catch((error) => {
  //  console.error('Error adding document: ', error);
  //  this.statusSubject.next({success: false, message: `Error adding client query,
  //  please check internet connection or log a support request.`});
 });
 }
 deleteClientQuery(item) {
   this.db.collection('clientQuery').doc(item.id).delete().then( () => {
     this.statusSubject.next({success: true, message: 'Client query data successfully deleted!'});
    //  console.log('Document successfully deleted');
 }).catch((error) => {
    //  console.error('Error removing document: ', error);
     this.statusSubject.next({success: false, message: `Error deleting inspection data,
     please check internet connection or log a support request.`});
 });
 }
 updateClientQuery(item, id) {
   const timestamp = this.timestamp;
  //  console.log(item);
   this.db.collection('clientQuery').doc(id).update({
    ...item,
    updatedAt: timestamp,
  }).then( () => {
    //  console.log('Document successfully updated');
     this.statusSubject.next({success: true, message: 'form successfully updated!'});
 }).catch((error) => {
     this.statusSubject.next({success: false, message: `Error updating form,
     please check internet connection or log a support request.`});
    //  console.error(error);
 });
 }

// Inspector submitted forms methods
getInspSubmitData() {
  this.inspSubmitData$ = this.db.collection('inspSubmittedForms').snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as Certificate;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
 this.inspSub = this.inspSubmitData$.subscribe(data => {
    this.inspSubmitDataSubject.next(data);
    // console.log('new data');
  });
  // console.log('getData');
}
submitForm(item) {
  const timestamp = this.timestamp;
  this.db.collection('inspSubmittedForms').add({
    ...item,
    updatedAt: timestamp,
    createdAt: timestamp,
  }).then((docRef) => {
  //  console.log('Document written with ID: ', docRef.id);
   this.statusSubject.next({done: true, success: true, message: 'Form successfully sent to the database!'});
 })
 .catch((error) => {
  //  console.error('Error adding document: ', error);
   this.statusSubject.next({success: false, message: `Error adding new form,
   please check internet connection or log a support request.`});
 });
 }
submitFormIf(item) {
  const timestamp = this.timestamp;
  this.db.collection('inspSubmittedForms').add({
    ...item,
    updatedAt: timestamp,
    createdAt: timestamp,
  }).then((docRef) => {
  //  console.log('Document written with ID: ', docRef.id);
   this.statusSubjectUploads.next({done: true, success: true, message: 'Form successfully sent to the database!'});
 })
 .catch((error) => {
  //  console.error('Error adding document: ', error);
  //  this.statusSubject.next({success: false, message: `Error adding new form,
  //  please check internet connection or log a support request.`});
 });
 }
 deleteForm(item) {
   this.db.collection('inspSubmittedForms').doc(item.id).delete().then( () => {
     this.statusSubject.next({success: true, message: 'Inspection data successfully deleted!'});
    //  console.log('Document successfully deleted');
 }).catch((error) => {
    //  console.error('Error removing document: ', error);
     this.statusSubject.next({success: false, message: `Error deleting inspection data,
     please check internet connection or log a support request.`});
 });
 }
 updateForm(item, id) {
   const timestamp = this.timestamp;
  //  console.log(item);
   this.db.collection('inspSubmittedForms').doc(id).update({
    ...item,
    updatedAt: timestamp,
  }).then( () => {
    //  console.log('Document successfully updated');
     this.statusSubject.next({success: true, message: 'form successfully updated!'});
 }).catch((error) => {
     this.statusSubject.next({success: false, message: `Error updating form,
     please check internet connection or log a support request.`});
     console.error(error);
 });
 }

// Misconduct methods
getMisconductData() {
  this.misconductData$ = this.db.collection('icsaMisconduct').snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as Certificate;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
 this.miscSub = this.misconductData$.subscribe(data => {
    this.misconductDataSubject.next(data);
    // console.log('new data');
  });
  // console.log('getData');
}

// Certificate methods
getCertificateData() {
  this.certificateData$ = this.db.collection('icsaData').snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as Certificate;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
this.certSub = this.certificateData$.subscribe(data => {
    this.certDataSubject.next(data);
    // console.log('new data');
  });
  // console.log('getData');
}
addCertificate(item) {
  const timestamp = this.timestamp;
 this.db.collection('icsaData').add({
  ...item,
  updatedAt: timestamp,
  createdAt: timestamp,
}).then((docRef) => {
  // console.log('Document written with ID: ', docRef.id);
  this.statusSubject.next({success: true, message: 'Certificate successfully created!'});
})
.catch((error) => {
  // console.error('Error adding document: ', error);
  this.statusSubject.next({success: false, message: `Error adding new certificate,
  please check internet connection or log a support request.`});
});
}
deleteItem(item) {
  this.db.collection('icsaData').doc(item.id).delete().then( () => {
    this.statusSubject.next({success: true, message: 'Certificate successfully deleted!'});
    // console.log('Document successfully deleted');
}).catch((error) => {
    // console.error('Error removing document: ', error);
    this.statusSubject.next({success: false, message: `Error deleting certificate,
    please check internet connection or log a support request.`});
});
}
updateItem(item, id) {
  const timestamp = this.timestamp;
  // console.log(item);
  this.db.collection('icsaData').doc(id).update({
    ...item,
    updatedAt: timestamp,
  }).then( () => {
    // console.log('Document successfully updated');
    this.statusSubject.next({success: true, message: 'Certificate successfully updated!'});
}).catch((error) => {
    this.statusSubject.next({success: false, message: `Error updating certificate,
    please check internet connection or log a support request.`});
    console.error(error);
});
}
updateAfterDelItem(item, id) {
  // console.log(item);
  this.db.collection('icsaData').doc(id).update(item).then( () => {
    // console.log('Image successfully deleted');
    this.statusSubject.next({delSuccess: true, message: 'Image successfully deleted'});
}).catch((error) => {
    this.statusSubject.next({delSuccess: false, message: `Error deleting image,
    please check internet connection or log a support request.`});
    // console.error(error);
});
}

// User methods
userRole(id) {
  // console.log('user role initiated');
this.userRole$ = this.db.collection('icsaUserRoles').doc(id).valueChanges();
this.roleSub = this.userRole$.subscribe(data => {
this.userRoleDataSubject.next(data);
});
}

// New requests
sendNewReq(item) {
  const timestamp = this.timestamp;
  this.db.collection('newRequests').add({
    ...item,
    updatedAt: timestamp,
    createdAt: timestamp,
  }).then((docRef) => {
    // console.log('Certificate request successfully created with ID: ', docRef.id);
    this.statusSubject.next({success: true, message: `Certificate request successfully sent.`});
  })
  .catch((error) => {
    // console.error('Error sending request: ', error);
    this.statusSubject.next({success: false, message: `Error creating certificate request,
    please check internet connection or email us directly: mail@domain`});
  });
}
getRequests() {
  this.requestsData$ = this.db.collection('newRequests', ref => ref.orderBy('requestDate').orderBy('time')).snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as Certificate;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
 this.reqSub = this.requestsData$.subscribe(data => {
    this.requestsDataSubject.next(data);
    // console.log('new data');
  });
  // console.log('getReqData');
}
deleteRequest(item) {
  this.db.collection('newRequests').doc(item.id).delete().then( () => {
    // console.log('Request successfully deleted');
    this.statusSubject.next({success: true, message: 'Request successfully deleted!'});
}).catch((error) => {
    console.error('Error deleting request: ', error);
    this.statusSubject.next({success: false, message: `Error deleting request,
    please check internet connection or log a support request.`});
});
}

// Tasks
createNewTask(item) {
  const timestamp = this.timestamp;
  this.db.collection('taskData').add({
    ...item,
    updatedAt: timestamp,
    createdAt: timestamp,
  }).then((docRef) => {
    // console.log('Task created with ID: ', docRef.id);
    this.statusSubject.next({success: true, message: 'Task successfully created.'});
  })
  .catch((error) => {
    // console.error('Error creating task: ', error);
    this.statusSubject.next({success: false, message: `Error creating task,
    please check internet connection or log a support request.`});
  });
}
getTasks() {
  this.taskData$ = this.db.collection('taskData', ref => ref.orderBy('dateCreated', 'desc')).snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as Certificate;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
 this.taskSub = this.taskData$.subscribe(data => {
    this.taskDataSubject.next(data);
    // console.log(data);
  });
  // console.log('getTaskData');
}
deleteTask(item, control) {
  // console.log('Delete task' + item);
  this.db.collection('taskData').doc(item.id).delete().then( () => {
    // console.log('Task successfully deleted');
    if (control.inspectionSubmitted === false) {
      this.statusSubject.next({success: true, message: 'Task successfully deleted!'});
    }
}).catch((error) => {
    // console.error('Error deleting task: ', error);
    this.statusSubject.next({success: false, message: `Error deleting task,
    please check internet connection or log a support request.`});
});
}
deleteTaskUploads(item) {
  // console.log('Delete task uploads');
  this.db.collection('taskData').doc(item.id).delete().then( () => {
    // console.log('Task successfully deleted');
    // this.statusSubject.next({success: true, message: 'Task successfully deleted!'});
}).catch((error) => {
    // console.error('Error deleting task: ', error);
    // this.statusSubject.next({success: false, message: `Error deleting task,
    // please check internet connection or log a support request.`});
});
}
updateTask(item, id) {
  const timestamp = this.timestamp;
  // console.log(item);
  this.db.collection('taskData').doc(id).update({
    ...item,
    updatedAt: timestamp,
  }).then(() => {
    // console.log('Task successfully updated');
    this.statusSubject.next({success: true, message: 'Task successfully updated!'});
}).catch((error) => {
    // console.error('Error updating Task: ', error);
    this.statusSubject.next({success: false, message: `Error updating task,
    please check internet connection or log a support request.`});
});
}

// HR methods
getHrData() {
  this.hrData$ = this.db.collection('hr').snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as Certificate;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
this.hrSub = this.hrData$.subscribe(data => {
    this.hrDataSubject.next(data);
    // console.log('new data');
  });
  // console.log('getData');
}
addHr(item) {
  const timestamp = this.timestamp;
  this.db.collection('hr').add({
    ...item,
    updatedAt: timestamp,
    createdAt: timestamp,
  }).then((docRef) => {
    // console.log('Task created with ID: ', docRef.id);
    this.statusSubject.next({success: true, message: 'Human resource successfully added.'});
  })
  .catch((error) => {
    // console.error('Error creating task: ', error);
    this.statusSubject.next({success: false, message: `Error adding human resource,
    please check internet connection or log a support request.`});
  });
}
addUserRole(value) {
  const timestamp = this.timestamp;
  this.db.collection('icsaUserRoles').doc(value.email).set({role: value.role}).then((docRef) => {
    // console.log('user role added ', docRef);
    // this.statusSubject.next({success: true, message: 'Human resource successfully added.'});
  })
  .catch((error) => {
    // console.error('Error creating user role: ', error);
    // this.statusSubject.next({success: false, message: `Error adding human resource,
    // please check internet connection or log a support request.`});
  });
}
delHr(item) {
  this.db.collection('hr').doc(item.id).delete().then( () => {
    // console.log('Human resource successfully deleted!');
    this.statusSubject.next({success: true, message: 'Human resource successfully deleted!'});
}).catch((error) => {
    // console.error('Error deleting hr: ', error);
    this.statusSubject.next({success: false, message: `Error deleting human resource,
    please check internet connection or log a support request.`});
});
}
updateHr(item, id) {
  const timestamp = this.timestamp;
  // console.log(item);
  this.db.collection('hr').doc(id).update({
    ...item,
    updatedAt: timestamp,
  }).then(() => {
    // console.log('Human resource successfully updated');
    this.statusSubject.next({success: true, message: 'Human resource successfully updated!'});
}).catch((error) => {
    // console.error('Error updating Task: ', error);
    this.statusSubject.next({success: false, message: `Error updating task,
    please check internet connection or log a support request.`});
});
}

// Halal list methods
getHalalData() {
  this.halalData$ = this.db.collection('halalList').snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as Certificate;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
 this.halalSub = this.halalData$.subscribe(data => {
    this.halalDataSubject.next(data);
    // console.log('Halal list data');
  });
}
addHalalItem(item) {
  const timestamp = this.timestamp;
  this.db.collection('halalList').add({
    ...item,
    updatedAt: timestamp,
    createdAt: timestamp,
  }).then((docRef) => {
    // console.log('Task created with ID: ', docRef.id);
    this.statusSubject.next({success: true, message: 'Halal list item successfully added.'});
  })
  .catch((error) => {
    // console.error('Error creating task: ', error);
    this.statusSubject.next({success: false, message: `Error adding human resource,
    please check internet connection or log a support request.`});
  });
}
delHalalItem(item) {
  this.db.collection('halalList').doc(item.id).delete().then( () => {
    // console.log('Halal ingredient successfully deleted!');
    this.statusSubject.next({success: true, message: 'Halal ingredient list item successfully deleted!'});
}).catch((error) => {
    // console.error('Error deleting halal ingredient: ', error);
    this.statusSubject.next({success: false, message: `Error deleting Halal list item,
    please check internet connection or log a support request.`});
});
}
updateHalalItem(item, id) {
  const timestamp = this.timestamp;
  // console.log(item);
  this.db.collection('halalList').doc(id).update({
    ...item,
    updatedAt: timestamp,
  }).then(() => {
    // console.log('Halal ingredient successfully updated');
    this.statusSubject.next({success: true, message: 'Halal list item successfully updated!'});
}).catch((error) => {
    console.error('Error updating Ingredient: ', error);
    this.statusSubject.next({success: false, message: `Error updating halal list item,
    please check internet connection or log a support request.`});
});
}

startListeningWatchFile() {
  // console.log('user role initiated');
this.watchFile$ = this.db.collection('icsa_crm').doc('watchFile').valueChanges();
this.watchFileSub = this.watchFile$.subscribe(data => {
  // console.log(data);
if (!data) {
  const shape = {
    adverts: [],
    posts: [],
    events: []
  };
  this.db.collection('icsa_crm').doc('watchFile').set(shape).then(() => {
    // console.log('Watch file shape set');
  }).catch((error) => {
    // console.error('Error setting watch file shape: ', error);
  });
} else {
  this.watchFileSubject.next(data);
  console.log(new Blob([data]).size);
}
});
}
tempFunctCT(item) {
  this.db.collection('staffOrder').doc('ct').set({
    item
  }).then(() => {
   console.log('set CT');
 })
 .catch((error) => {
   console.error('Error adding document: ', error);
 });
 }
tempFunctJHB(item) {
  this.db.collection('staffOrder').doc('jhb').set({
    item
  }).then(() => {
   console.log('set CT');
 })
 .catch((error) => {
   console.error('Error adding document: ', error);
 });
 }
tempFunctDBN(item) {
  this.db.collection('staffOrder').doc('dbn').set({
    item
  }).then(() => {
   console.log('set CT');
 })
 .catch((error) => {
   console.error('Error adding document: ', error);
 });
 }
getStaffOrderData() {
  this.staffOrderData$ = this.db.collection('staffOrder').snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as Certificate;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
 this.staffOrderSub = this.staffOrderData$.subscribe(data => {
    this.staffOrderDataSubject.next(data);
  });
}
}
