import { Component, OnInit, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { FirestoreService } from '../services/firestore.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {MatSnackBar} from '@angular/material';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-varify-del',
  templateUrl: './varify-del.component.html',
  styleUrls: ['./varify-del.component.css']
})
export class VarifyDelComponent implements OnInit, OnDestroy, AfterViewInit {
  statusEvent: any;
  subscription: Subscription;
  title: any;
  constructor(private db: FirestoreService,
    private dialogRef: MatDialogRef<VarifyDelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar) {
     }

  del() {
    if (this.data.method === 'deleteCert') {
    this.db.deleteItem(this.data.data);
    }
    if (this.data.method === 'deleteHr') {
    this.db.delHr(this.data.data);
    }
    if (this.data.method === 'deleteIngredient') {
    this.db.delHalalItem(this.data.data);
    }
    if (this.data.method === 'deleteTask') {
    this.db.deleteTask(this.data.data, {inspectionSubmitted: false});
    }
    if (this.data.method === 'deleteRequest') {
    this.db.deleteRequest(this.data.data);
    }
    if (this.data.method === 'deleteInspData') {
    this.db.deleteForm(this.data.data);
    }
    if (this.data.method === 'deleteClientQuery') {
    this.db.deleteClientQuery(this.data.data);
    }
    if (this.data.method === 'deleteProcessedClientQuery') {
    this.db.deleteProcessedClientQuery(this.data.data);
    }
  }
  cancel() {
    this.dialogRef.close('remove-class');
  }
  ngOnInit() {
    this.subscription = this.db.statusSubject.subscribe(status => {
      this.statusEvent = status;
      console.log(status);
      if (this.statusEvent.success === true) {
        this.dialogRef.close('deleted');
        // setTimeout(() => {
        //   const snackBarRef = this.snackBar.open(`${this.statusEvent.message}`, 'Dissmiss', {duration: 2000, });
        //   snackBarRef.onAction().subscribe(() => {
        //   snackBarRef.dismiss();
        //   });
        // }, 2000);
      } if (this.statusEvent.success === false) {
        this.dialogRef.close('deleted');
        // const snackBarRef = this.snackBar.open(`${this.statusEvent.message}`, 'Dissmiss');
        // snackBarRef.onAction().subscribe(() => {
        // snackBarRef.dismiss();
        // });
      }
      });
      if (this.data.method === 'deleteCert') {
        this.title = 'Are you sure that you want to delete this certificate?';
        }
        if (this.data.method === 'deleteHr') {
          this.title = 'Are you sure that you want to delete this staff member from the database?';
        }
        if (this.data.method === 'deleteIngredient') {
          this.title = 'Are you sure that you want to delete this halal ingredient from the database?';
        }
        if (this.data.method === 'deleteTask') {
          this.title = 'Are you sure that you want to delete this task?';
        }
        if (this.data.method === 'deleteRequest') {
          this.title = 'Are you sure that you want to delete this Request?';
        }
        if (this.data.method === 'deleteInspData') {
          this.title = 'Are you sure that you want to delete this inspection data?';
        }
        if (this.data.method === 'deleteClientQuery') {
          this.title = 'Are you sure that you want to delete this data?';
        }
        if (this.data.method === 'deleteProcessedClientQuery') {
          this.title = 'Are you sure that you want to delete this data?';
        }
  }
  ngOnDestroy() {
  this.subscription.unsubscribe();
  }
  ngAfterViewInit() {
  }

}
