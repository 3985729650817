import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import {Router} from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-advert-application',
  templateUrl: './advert-application.component.html',
  styleUrls: ['./advert-application.component.css']
})
export class AdvertApplicationComponent implements OnInit {
  @ViewChild('changebtn') private changebtn: ElementRef;
  message = 'Do you have a halal certificate with us?';
  initBtns = true;
  navigateCertReq = false;
  submitForm = false;
  contactDetailsForm: FormGroup;
  constructor(private router: Router, private http: HttpClient,
    private dialogRef: MatDialogRef<AdvertApplicationComponent>) { }

  ngOnInit() {
    const clearFocus = setInterval(() => {
      this.changebtn['_elementRef'].nativeElement.classList.remove('cdk-program-focused');
      }, 50);
      setTimeout(() => {clearInterval(clearFocus); }, 200);
      this.contactDetailsForm = new FormGroup({
        name: new FormControl('', Validators.required),
        businessName: new FormControl('', Validators.required),
        certificateNumber: new FormControl('', Validators.required),
        tel: new FormControl('' , Validators.compose([Validators.required, Validators.minLength(9)])),
        email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      });
  }
  process(val) {
if (val === 'yes') {
  this.message = 'Please enter your contact details, also note that adverts are billed at R300 per month, thank you.';
  this.submitForm = true;
  this.initBtns = false;
}
if (val === 'no') {
  this.initBtns = false;
  this.navigateCertReq = true;
  this.message = 'Please request a halal certificate from us and opt in for advertising.';
}
if (val === 'navigate') {
  this.router.navigateByUrl('/Certificate-request');
  this.dialogRef.close();
}
  }
  submit() {
    const uploadData = {
      name: this.contactDetailsForm.value['name'],
      email: this.contactDetailsForm.value['email'],
      tel: this.contactDetailsForm.value['tel'],
      businessName: this.contactDetailsForm.value['businessName'],
      certificateNumber: this.contactDetailsForm.value['certificateNumber']
      };
      this.http.post('http://www.islamiccouncilsa.co.za/advert_opt_in.php', uploadData)
        .subscribe((event: any) => {
          // console.log(event);
          if (event.success === true) {
            this.message = 'Advert request submitted successfully, we will be in contact with you with further details.';
            setTimeout(() => {
              this.dialogRef.close();
            }, 4000);

          }
          if (event.success === false) {
            this.message = 'Error sending your request, please check your internet connection or try again later.';
            setTimeout(() => {
              this.dialogRef.close();
            }, 6000);
          }
        });

  }

}
