import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cert-request-crm',
  templateUrl: './cert-request-crm.component.html',
  styleUrls: ['./cert-request-crm.component.css']
})
export class CertRequestCrmComponent implements OnInit {
showForm = false;
  constructor() { }
applyOnline() {
  this.showForm = true;
}
return() {
  this.showForm = false;
}
  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
