import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouterLinkActive } from '@angular/router';

import { NotFoundComponent } from './not-found/not-found.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { AuthGuard } from './services/auth-service/auth-guard.service';

import { MobileCertReqComponent } from './mobile-cert-req/mobile-cert-req.component';

import { ForUploadComponent } from './for-upload/for-upload.component';
import { ConferencesComponent } from './conferences/conferences.component';
import { ProjectsComponent } from './projects/projects.component';
import { MediaComponent } from './media/media.component';
import { NewsComponent } from './news/news.component';
import { BiotechComponent } from './biotech/biotech.component';
import { ContactComponent } from './contact/contact.component';
import { CertRequestCrmComponent } from './cert-request-crm/cert-request-crm.component';
import { HomePageComponent } from './home-page-alt/home-page.component';

const routes: Routes = [
  {path: '', redirectTo: 'Home', pathMatch: 'full'},
  {path: 'not-found', component: NotFoundComponent},
  {path: 'Login', component: LoginPageComponent},
  {path: 'Request-certificate', component: MobileCertReqComponent},
  {path: 'Uploads', canActivate: [AuthGuard], component: ForUploadComponent},
  {path: 'Home', component: HomePageComponent},
  // {path: 'About', loadChildren: () => import('./about/about.module').then(m => m.AboutModule)},
  {path: 'About', loadChildren: './about/about.module#AboutModule'},
  {path: 'Conference', component: ConferencesComponent},
  {path: 'Projects', component: ProjectsComponent},
  {path: 'Media', component: MediaComponent},
  {path: 'News', component: NewsComponent},
  {path: 'Biotech', component: BiotechComponent},
  {path: 'Contact-us', component: ContactComponent},
  {path: 'Certificate-request', component: CertRequestCrmComponent},
  {path: '**', redirectTo: './not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule, RouterLinkActive]
})
export class AppRoutingModule { }
export const routingComponents = [
  NotFoundComponent,
  LoginPageComponent,
  HomePageComponent,
  MobileCertReqComponent,
  ForUploadComponent,
  ConferencesComponent,
    ProjectsComponent,
    MediaComponent,
    NewsComponent,
    BiotechComponent,
    ContactComponent,
    CertRequestCrmComponent,
];
