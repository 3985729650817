import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FirestoreService } from '../services/firestore.service';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { UploaderComponent } from '../uploader/uploader.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-cert-req',
  templateUrl: './mobile-cert-req.component.html',
  styleUrls: ['./mobile-cert-req.component.css']
})
export class MobileCertReqComponent implements OnInit, OnDestroy {
  addCertificateForm = new FormGroup({
    name: new FormControl(''),
    address: new FormControl(''),
    note: new FormControl(''),
    tel: new FormControl(''),
    email: new FormControl(''),
    status: new FormControl('Pending'),
  });
  documents = new FormGroup({
  });
  date: any;
  data: {requestDate: any, time: any, uploadedDocuments: any[]};
  time: any;
  uploadsComplete = false;
  uploadedDocuments: Array<any> = [];
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 414px)'])
  .pipe(
    map(result => result.matches)
  );
isMobile = false;
enableFirstStep = false;
response: Subscription;
@ViewChild('upload') uploader: UploaderComponent;
submitMessage = 'Please submit your request';
dissableAfterSubmit = false;
submitLoader = false;
  constructor(private db: FirestoreService, public datepipe: DatePipe,
    private breakpointObserver: BreakpointObserver, private router: Router) { this.isHandset$.subscribe(result => {
    if (result === true) {
      // console.log('matches');
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  });
}

  tryAdd(value) {
    this.date = Date.now();
    const latest_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    const latest_time = this.datepipe.transform(this.date, 'HH:mm');
    this.data = value;
    this.data.requestDate = latest_date;
    this.data.time = latest_time;
    this.data.uploadedDocuments = this.uploadedDocuments;
    // console.log(this.data);
    this.db.sendNewReq(this.data);
    this.submitLoader = true;
    }


    onUploadComplete(data) {
    const documentUrl = data.documentUrl;
    const documentName = data.file;
    this.uploadedDocuments.push({documentUrl: documentUrl, documentName: documentName});
    // console.log(this.uploadedDocuments);
    if (this.uploadedDocuments.length === data.arrLength) {
      this.uploadsComplete = true;
      this.uploader.hideProgress = true;
    }
  }
  ngOnInit() {
   this.response = this.db.statusSubject.subscribe(data => {
      const res: any = data;
      if (res.success === true) {
        this.submitMessage = 'Thank you for your submission, we will be in contact with you regarding the status of your request.';
        this.dissableAfterSubmit = true;
        this.submitLoader = false;
        setTimeout(() => {
          this.router.navigate(['Home']);
        }, 3000);
      }
      if (res.success === false) {
        this.submitLoader = false;
        this.submitMessage = 'There was an error submitting your requeset, please check your internet connection or try again later.';
      }
    });
    window.scrollTo(0, 0);
  }

  resetUpload() {
this.uploader.reset();
this.dissableAfterSubmit = false;
  }
//   resolved(captchaResponse: string) {
//     console.log(`Resolved captcha with response ${captchaResponse}:`);
// }
  resolved(event) {
    // console.log(event);
    if (event) {
    this.enableFirstStep = true;
    }
}
ngOnDestroy () {
this.response.unsubscribe();
}
}
