import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { finalize, tap, map } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.css']
})

export class UploaderComponent implements OnInit, OnDestroy {
// Main task
task: AngularFireUploadTask;

// Progress monitoring
percentage: Observable<number>;

snapshot: Observable<any>;

// Download URL
downloadURL: Observable<string>;

// State for dropzone CSS toggling
isHovering: boolean;
@Input() hideProgress = false;
@Output() eventUploadUrl = new EventEmitter<any>();
isHandset$: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 414px)'])
  .pipe(
    map(result => result.matches)
  );
isMobile = false;
// selectedFile = 'No file selected';
selectedFile: Array<any> = [];
// file: any;
fileArr: any[];
@ViewChild('fileUploadEl') fileUpload: ElementRef;
taskSubscription: Subscription;
arrLengthCntrl: any;
uploadCount: number;
constructor(private storage: AngularFireStorage,
  private db: AngularFirestore, private breakpointObserver: BreakpointObserver) {
    this.isHandset$.subscribe(result => {
      if (result === true) {
        console.log('matches');
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
   }


toggleHover(event: boolean) {
  this.isHovering = event;
}


startUpload(event: FileList) {
  this.uploadCount++;
  console.log(event);
  // this.fileArr = event.target.files
  // let reservationArr : Array<object> = [];
  let i;
  for (i = 0; i < event.length; i++) {
  console.log(event.item(i));


  // The File object
  const uploadArrLength = event.length;
  this.arrLengthCntrl = uploadArrLength;
  const file = event.item(i);
  this.selectedFile.push(event.item(i).name);
  // Client-side validation example
  // if (file.type.split('/')[0] !== '.pdf') {
  //   console.error('unsupported file type :( ');
  //   return;
  // }
  console.log(file.type);

  // The storage path
  const path = `certReq/${new Date().getTime()}_${file.name}`;

  // Totally optional metadata
  const customMetadata = { size: `${file.size}` };

  // The main task
  this.task = this.storage.upload(path, file, { customMetadata });

  // Progress monitoring
  this.percentage = this.task.percentageChanges();
  this.snapshot   = this.task.snapshotChanges().pipe(
    tap(snap => {
      if (snap.bytesTransferred === snap.totalBytes) {
        // Update firestore on completion
        this.db.collection('photos').add( { path, size: snap.totalBytes });
        // this.hideProgress = true;
      }
    })
  );
  const fileRef = this.storage.ref(path);
  // The file's download URL
//  this.downloadURL = this.task.downloadURL();
this.taskSubscription = this.task.snapshotChanges().pipe(
  finalize(() => {
    this.downloadURL = fileRef.getDownloadURL();
    this.downloadURL.subscribe(data => {
      const x = {documentUrl: data, file: file.name, arrLength: uploadArrLength};
      this.eventUploadUrl.emit(x);
      console.log(data);
    });
  } )
)
.subscribe();
}
}

// Determines if the upload task is active
isActive(snapshot) {
  return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
}
ngOnInit() {
  this.uploadCount = 0;
}
reset() {
  console.log(this.fileUpload.nativeElement);
  // this.file = null;
this.fileUpload.nativeElement.value = '';
this.taskSubscription.unsubscribe();
this.percentage = null;
this.hideProgress = false;
this.selectedFile = [];
}
ngOnDestroy() {
  if (this.uploadCount >= 1) {
    this.taskSubscription.unsubscribe();
  }

}
}
